// src/pages/HomePage.tsx
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Select from 'react-select';
import { 
  useTable, 
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useSortBy,
  UseSortByInstanceProps,
  Column, 
  Row ,
  TableState
} from 'react-table';
import styled from 'styled-components';
import Carousel from '../components/Carousel';

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

type Question = {
  problemType: string;
  title: string;
  solution: string;
  companyTags: string[];
  tags: string[];
  difficulty: string;
  lastAsked: string;
};

// Mock data for the table
const mockData: Question[] = [
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Two Sum",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook"],
    tags: ["Array", "Hash Table"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  {
    problemType: "Algorithm",
    title: "Maximum Sum of Subsequence With Non-adjacent Elements",
    solution: "/solution/two-sum",
    companyTags: ["Google", "Facebook", "Amazon", "Oracle", "Flipkart", "Phonepe", "Zepto", "Kalerya", "Swiggy", "Uber", "Zoho"],
    tags: ["Array", "Hash Table", "BFS", "DFS", "Dynamic Programming", "Multithreading", "Recursion"],
    difficulty: "Easy",
    lastAsked: "2024-11-10"
  },
  // Additional data here...
];

// Sample options for searchable dropdowns
const companyOptions = [
  { value: 'Google', label: 'Google' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Amazon', label: 'Amazon' }
];

const tagOptions = [
  { value: 'Array', label: 'Array' },
  { value: 'Hash Table', label: 'Hash Table' },
  { value: 'String', label: 'String' }
];


// const difficultyOptions = [
//   { value: 'Easy', label: 'Easy' },
//   { value: 'Medium', label: 'Medium' },
//   { value: 'Hard', label: 'Hard' }
// ];

interface Option {
  value: string;
  label: string;
  color: string; // Add a color property to each option
}

const difficultyOptions: Option[] = [
  { value: "Easy", label: "Easy", color: "#28a745" },
  { value: "Medium", label: "Medium", color: "#ffc107" },
  { value: "Hard", label: "Hard", color: "#dc3545" },
  { value: "info", label: "Info", color: "#17a2b8" },
];

// const difficultyOptionStyles = {
//   control: (provided: any) => ({
//     ...provided,
//     padding: "5px",
//   }),
//   option: (provided: any, state: any) => ({
//     ...provided,
//     color: state.data.color,
//     backgroundColor: state.isFocused ? "#f8f9fa" : "white",
//     padding: 10,
//   }),
//   singleValue: (provided: any, state: any) => ({
//     ...provided,
//     color: state.data.color,
//   }),
//   multiValue: (provided: any, state: any) => ({
//     ...provided,
//     backgroundColor: state.data.color,
//     color: "white",
//     borderRadius: "4px",
//   }),
//   multiValueLabel: (provided: any) => ({
//     ...provided,
//     color: "white",
//   }),
//   multiValueRemove: (provided: any) => ({
//     ...provided,
//     color: "white",
//     ":hover": {
//       backgroundColor: "darkgray",
//       color: "black",
//     },
//   }),
// };

const statusOptions = [
  { value: 'Solved', label: 'Solved' },
  { value: 'Unsolved', label: 'Unsolved' }
];

const pageSizeOptions = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' }
];

const HomePage: React.FC = () => {
  // const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({
    list: '',
    difficulty: '',
    status: '',
    tags: [],
    companyTags: [],
    search: ''
  });

  const columns: Column<Question>[] = React.useMemo(
    () => [
      { Header: 'Problem Type', accessor: 'problemType' },
      { 
        Header: 'Title', 
        accessor: 'title',
        Cell: ({ value }) => (
          <TitleLink href="#" title={value}>
            {value.length > 50 ? `${value.slice(0, 50)}...` : value}
          </TitleLink>
        )
      },
      { Header: 'Solution', accessor: 'solution' },
      { 
        Header: 'Company Tags', 
        accessor: 'companyTags',
        Cell: ({ value }) => <TagCell tags={value} />
      },
      { 
        Header: 'Tags', 
        accessor: 'tags',
        Cell: ({ value }) => <TagCell tags={value} /> 
      },
      { 
        Header: 'Difficulty', 
        accessor: 'difficulty',
        Cell: ({ value }) => <TagCell tags={[value]} />
      },
      { Header: 'Last Asked Date', accessor: 'lastAsked' },
    ],
    []
  );

  const data = React.useMemo(() => mockData, []);

  const tableInstance = useTable(
    { 
      columns, 
      data,
      initialState: { pageSize: 10, pageIndex: 0 }  as Partial<TableState<Question>>,
    },
    usePagination
  ) as TableInstanceWithHooks<Question>;

  const handleFilterChange = (name: string, value: any) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePageSizeChange = (selectedOption: any) => {
    setPageSize(selectedOption.value);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance;

  const topCarouselItems = [
    <iframe 
        width="100%" height="100%" 
        src="https://www.youtube.com/embed/-C1--vDGHj0" 
        title="Leetcode 1011: Capacity To Ship Packages Within D Days (Medium)" 
        // frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        // referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
    />,
    <img
      key="image1"
      src="https://img.youtube.com/vi/-C1--vDGHj0/hqdefault.jpg"
      alt="Sample Image"
      style={{ width: "100%", height: "100%"}}
    />,
    "Item 1", 
    "Item 2", 
    "Item 3", 
    "Item 4", 
    "Item 5", 
    "Item 6", 
    "Item 7", 
    "Item 8", 
    "Item 9", 
    "Item 10", 
    "Item 11", 
    "Item 12", 
    "Item 13", 
    "Item 14",
    <img
      key="image1"
      src="https://upload.wikimedia.org/wikipedia/commons/4/4a/100x100_logo.png"
      alt="Sample Image"
      style={{ width: "100%", height: "100%" }}
    />
  ]

  return (
    <div>
      <Navbar />
      <MainContent>
        {/* <div style={{ padding: "1rem" }}>
          <h2>Recent Contents</h2>
          <Carousel items={topCarouselItems} />
        </div> */}

        {/* New start */}

        <ContentWrapper>
          <TableSection>
            <FilterSection
              tagOptions={tagOptions}
              difficultyOptions={difficultyOptions}
              statusOptions={statusOptions}
              companyOptions={companyOptions}
              handleFilterChange={handleFilterChange}
            />
            <ProblemTable
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            />
            <PaginationContainer>
              <div>
                <span>Rows per page: </span>
                <Select
                  options={pageSizeOptions}
                  defaultValue={pageSizeOptions.find(option => option.value === pageSize)}
                  onChange={handlePageSizeChange}
                />
              </div>
              <div>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>First</button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                <span>
                  Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>Last</button>
              </div>
            </PaginationContainer>
          </TableSection>
          <AdSection>
            <p></p>
            {/* <p>Ad Space</p> Add your ad content here */}
          </AdSection>
        </ContentWrapper>

        {/* New End */}

      </MainContent>
    </div>
  );
};

export default HomePage;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;

  button {
    padding: 0.5rem;
    margin: 0 0.25rem;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
`;

// Custom component to render tags with "Show all" functionality
const TagCell: React.FC<{ tags: string[] }> = ({ tags }) => {
  const [showAll, setShowAll] = useState(false);
  const displayedTags = showAll ? tags : tags.slice(0, 4);

  return (
    <TagContainer>
      {displayedTags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
      {tags.length > 4 && (
        <ShowMore onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show less' : 'Show all'}
        </ShowMore>
      )}
    </TagContainer>
  );
};

// Styled components for tags
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: calc(3 * 100px); /* Adjust to fit 3 tags per row */
`;

const Tag = styled.span`
  background-color: #f0f0f0;
  color: #333;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9rem;
`;

const ShowMore = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  margin-left: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

const TitleLink = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #007bff; /* Change color on hover */
    text-decoration: underline;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const TableSection = styled.div`
  flex: 0 0 70%; /* Takes 75% of the width */
  margin: 0 auto;
`;


const AdSection = styled.div`
  flex: 0 0 21%; /* Takes 25% of the width */
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 400px; /* 100% view not proper */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
`;

// interface TableProps {
//   getTableProps: () => object;
//   getTableBodyProps: () => object;
//   headerGroups: any[]; // Type for headerGroups
//   rows: Row<any>[]; // Type for rows, using any as data can be dynamic
//   prepareRow: (row: Row<any>) => void;
// }

interface TableProps {
  getTableProps: () => any; // Props for the table element
  getTableBodyProps: () => any; // Props for the table body
  headerGroups: any[]; // Header groups
  rows: Row<any>[]; // Rows to display
  prepareRow: (row: Row<any>) => void; // Prepares the row for rendering
  pageOptions?: number[]; // For pagination
  pageCount?: number; // For pagination
  pageIndex?: number; // Current page index
  pageSize?: number; // Rows per page
  setPageSize?: (size: number) => void; // Method to set page size
  gotoPage?: (page: number) => void; // Method to navigate to a specific page
}

// New Table Component
const ProblemTable: React.FC<TableProps> = ({ getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }) => {
  return (
    <TableContainer>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, index: number) => ( // Explicitly typing column here
                <th {...column.getHeaderProps()} key={index}>
                  {typeof column.render === 'function' ? column.render('Header') : column.Header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.column.id === 'solution' ? (
                      <a href={cell.value}>Solution</a>
                    ) : (
                      cell.render('Cell')
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableContainer>
  );
};


// FilterSection Component
const FilterSection: React.FC<{
  tagOptions: any[];
  difficultyOptions: any[];
  statusOptions: any[];
  companyOptions: any[];
  handleFilterChange: (filter: string, value: any) => void;
}> = ({ tagOptions, difficultyOptions, statusOptions, companyOptions, handleFilterChange }) => {
  return (
    <FilterSectionContainer>
      <Filters>
        <FilterItem>
          <Select 
            isSearchable
            options={tagOptions}
            defaultValue={null}
            onChange={value => handleFilterChange('list', value)}
            placeholder="Lists"
          />
        </FilterItem>
        <FilterItem>
          <Select
            options={difficultyOptions}
            // styles={difficultyOptionStyles}
            defaultValue={null}
            onChange={value => handleFilterChange('difficulty', value)}
            placeholder="Difficulty"
          />
        </FilterItem>
        <FilterItem>
          <Select
            options={statusOptions}
            defaultValue={null}
            onChange={value => handleFilterChange('status', value)}
            placeholder="Status"
          />
        </FilterItem>
        <FilterItem>
          <Select
            isMulti
            isSearchable
            options={tagOptions}
            defaultValue={null}
            onChange={value => handleFilterChange('tags', value)}
            placeholder="Tags"
          />
        </FilterItem>
        <FilterItem>
          <Select
            isMulti
            isSearchable
            options={companyOptions}
            defaultValue={null}
            onChange={value => handleFilterChange('companyTags', value)}
            placeholder="Company Tags"
          />
        </FilterItem>
        <FilterItem>
          <SearchInput
            type="text"
            placeholder="Search questions"
            onChange={e => handleFilterChange('search', e.target.value)}
          />
        </FilterItem>
      </Filters>
      </FilterSectionContainer>
  );
};

const SearchInput = styled.input`
  height: 40px;  /* Same height as Select dropdown */
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;


const FilterSectionContainer = styled.div`
  margin-bottom: 1rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.div`
  margin-top: 30px;
  flex: 8;
  padding: 1rem;
  background-color: #f9f9f9; // Optional background color for main content
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
`;

// Styled components for layout
const Filters = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
`;


const TableContainer = styled.div`
  margin-top: 2rem;
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 1rem;
  table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      padding: 10px;
      // border: 1px solid #ddd;
    }
    th {
      background-color: #f2f2f2;
      text-align: left;
    }
    
    tbody tr:nth-child(odd) {
      background-color: #ffffff; /* White for odd rows */
    }

    tbody tr:nth-child(even) {
      background-color: #f7f7f7; /* Light gray for even rows */
    }
    td a {
      color: #007bff;
      text-decoration: none;
    }
  }
`;