// src/components/Navbar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaReact, FaCompass, FaTasks, FaTrophy, FaComments, FaUserTie, FaUser, FaCrown } from 'react-icons/fa';

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  box-sizing: border-box;
  padding: 1rem 2rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensures it stays on top of other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const NavSection = styled.div`
  display: flex;
  gap: 2rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const AppIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #61dafb; // React blue color
`;

const Navbar: React.FC = () => {
  return (
    <NavbarContainer>
      <NavSection>
        <AppIcon>
          <FaReact /> {/* App Icon (Replace with your logo icon if desired) */}
        </AppIcon>
        <NavLink to="/explore">Explore</NavLink>
        <NavLink to="/problems">Problems</NavLink>
        <NavLink to="/contest">Contest</NavLink>
        <NavLink to="/discuss">Discuss</NavLink>
        <NavLink to="/interview">Interview</NavLink>
      </NavSection>
      <NavSection>
        <NavLink to="/register">Register or Sign In</NavLink>
        <NavLink to="/premium">Premium</NavLink>
        {/* <div></div> */}
      </NavSection>
    </NavbarContainer>
  );
};

export default Navbar;
