// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import MainApp from './components/MainApp';

// function App() {
//   return (
//     <MainApp/>
//   );
// }

// export default App;

// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/explore" element={<div>Explore Page</div>} />
        <Route path="/problems" element={<div>Problems Page</div>} />
        <Route path="/contest" element={<div>Contest Page</div>} />
        <Route path="/discuss" element={<div>Discuss Page</div>} />
        <Route path="/interview" element={<div>Interview Page</div>} />
        <Route path="/register" element={<div>Register or Sign In</div>} />
        <Route path="/premium" element={<div>Premium Page</div>} />
      </Routes>
    </Router>
  );
};

export default App;
